@import "https://use.fontawesome.com/ede1f9233a.css";
@import "fonts";
#CCIPlaceHolder_Content{
  margin-top: 3rem;
  section{
    p a{
      text-decoration: underline;
    }
  }
}
/*header*/
.header.variant1 {
  .fixed {
    padding: 0.75rem 0;
    .bottom .toggle-topbar.menu-icon + .left img {
      max-height: 90px;
    }
  }
  .bottom .toggle-topbar.menu-icon + .left img {
    max-height: 105px;
    margin-top: 0;
  }
}
.header.variant1 .top-bar-section ul li > a{
  color: #ffffff !important;
}
.logoutBtn{
  display: none !important;
}
/*Homepage specific*/
.home {
  .block.slider {
    margin-bottom: 2.5rem;
  }
}

/*Blok in beeld*/
.uitgelichtInbeeld {
  margin-bottom: 5rem;
  figure {
    margin: 0;
  }
  h4 {
    margin: 1rem 0 0 0;
  }
}

/*Meetings/ageda*/
.meetingsNotFound{
  display: none;
}

/*Font size changer*/
body {
  #fontSize {
    float: left;
    line-height: 1;
    margin: 0 -5px 0 0;
    span {
      padding: 0 5px;
      cursor: pointer;
      &:hover {
        color: #bd8b40;
      }
    }
  }
  &.small {
    main {
      p,
      span,
      strong,
      i,
      em,
      a,
      span a {
        font-size: 12px;
      }
    }
  }
  &.regular {
    main {
      p,
      span,
      strong,
      i,
      em,
      a,
      span a {
        font-size: 16px;
      }
    }
  }
  &.large {
    main {
      p,
      span,
      strong,
      i,
      em,
      a,
      span a {
        font-size: 32px;
      }
    }
  }
}

.image-centered {
  display: block;
  float: none !important;
  margin: 10px auto !important;
}

.image-left {
  float: left !important;
  margin: 10px 20px 10px 0 !important;
}

.image-right {
  float: right !important;
  margin: 10px 0 10px 20px !important;
}

.slider.variant1 .slick-dots li button:before {
	border:0;
}

time {
	font-size:.85rem;
}

section {
  .meetingsSmall,
  .pagesSmall,
  .pages {
    article {
      border-bottom: 0!important;
    }
  }
}

@media screen and(max-width:768px){
  .top-bar {
    height: 7.625rem; 
  }
  .header.variant1 .bottomWrapper {
    border-bottom:0;
  }
}