// Campton
@font-face {
    font-family: 'Campton-Bold';
    src: url('/fonts/Campton/Campton-Bold.eot');
    src: url('/fonts/Campton/Campton-Bold.woff2') format('woff2'),
    url('/fonts/Campton/Campton-Bold.woff') format('woff'),
    url('/fonts/Campton/Campton-Bold.ttf') format('truetype'),
    url('/fonts/Campton/-Bold.eot?#iefix') format('embedded-opentype');
  }
  
  @font-face {
    font-family: 'Campton-Light';
    src: url('/fonts/Campton/Campton-Light.eot');
    src: url('/fonts/Campton/Campton-Light.woff2') format('woff2'),
    url('/fonts/Campton/Campton-Light.woff') format('woff'),
    url('/fonts/Campton/Campton-Light.ttf') format('truetype'),
    url('/fonts/Campton/Campton-Light.eot?#iefix') format('embedded-opentype');
  }
  
  @mixin fontCamptonBold() {
    font-family:"Campton-Bold", Helvetica, Arial, sans-serif;
  }
  @mixin fontCamptonLight() {
    font-family:"Campton-Light", Helvetica, Arial, sans-serif;
  }

  // Archivo  
  @font-face {
    font-family: 'Archivo-Regular';
    src: url('/fonts/Archivo/Archivo-Regular.woff2') format('woff2'),
    url('/fonts/Archivo/Archivo-Regular.woff') format('woff'),
    url('/fonts/Archivo/Archivo-Regular.ttf') format('truetype'),
  }

  @font-face {
    font-family: 'Archivo-Bold';
    src: url('/fonts/Archivo/Archivo-Bold.woff2') format('woff2'),
    url('/fonts/Archivo/Archivo-Bold.woff') format('woff'),
    url('/fonts/Archivo/Archivo-Bold.ttf') format('truetype'),
  }

  @mixin Archivo-regular() {
    font-family:"Archivo-Regular", Helvetica, Arial, sans-serif;
  }
  @mixin Archivo-bold() {
    font-family:"Archivo-Bold", Helvetica, Arial, sans-serif;
  }